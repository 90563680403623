import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import CircleIcon from '@mui/icons-material/Circle';
import { FingoDialog } from '../dialogs';
import { useBooleanState } from '../../hooks';
import { RestrictionsColorMapper } from '../../helpers';

const PendingDocumentsManagerFooterCell = ({ type, executiveAssigned }) => {
  if (type === 'Operations') {
    return (
      <Typography fontWeight="Bold" alignSelf="center">
        Posterior a ello se realizará el giro
      </Typography>
    );
  }
  return (
    <Stack direction="row" alignItems="center">
      <Box sx={{ width: '50%' }}>
        <Typography fontWeight="Bold">
          Para regularizar aspectos, envía la documentación a tu ejecutivo:
        </Typography>
      </Box>
      <Box
        sx={{ width: '50%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={{ width: 45, height: 45 }}>
            <Typography variant="subtitle2">E</Typography>
          </Avatar>
          <Stack spacing={0.3}>
            <Typography>{executiveAssigned.completeName}</Typography>
            <Stack direction="row" alignItems="center" spacing={0.3}>
              <WhatsAppIcon sx={{ width: 15, height: 15, color: '#25d366' }} />
              <Typography>
                {executiveAssigned.phoneNumber?.replace(
                  /(\+\d{2})(\d)(\d{4})(\d{4})/,
                  '$1 $2 $3 $4',
                ) ?? null}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.3}>
              <EmailIcon color="primary" sx={{ width: 15, height: 15 }} />
              <Typography>{executiveAssigned.email}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

const PendingDocumentsManagerCell = ({ invoice, type }) => {
  if (invoice.status === 'REJECTEDPREOFFEREVALUATION') return null;
  if (
    ['EVALUATING', 'DOCUMENTSREVIEWED'].includes(
      invoice.preoffer?.preofferevaluationrequest?.status,
    )
  ) {
    return null;
  }
  const [openDialog, setOpenDialog] = useBooleanState();
  const filteredRestrictions = invoice.restrictedBy?.filter(
    (res) => ['CREATED', 'PENDING'].includes(res.status)
      && res.restrictionType === type
      && res.restriction !== 'OTHER',
  );
  const avatarBgColor = () => {
    if (!filteredRestrictions.length) return '';
    if (type === 'Risk') return 'primary.main';
    return '#ff9800';
  };
  const dialogSubtitle = {
    Risk: 'Para poder avanzar con la evaluación necesitamos aclarar los siguientes aspectos:',
    Operations:
      'Al ceder esta factura se te pedira regularizar los siguientes aspectos:',
  };
  const tootlipLabel = {
    Risk: 'Se están revisando tus documentos pendientes',
    Operations: 'Esta factura no tiene condiciones pendientes para girar',
  };
  return (
    <>
      <Tooltip
        title={
          !filteredRestrictions.length ? (
            <Typography>{tootlipLabel[type]}</Typography>
          ) : null
        }
      >
        <Avatar
          onClick={() => {
            if (filteredRestrictions.length) {
              setOpenDialog();
            }
          }}
          disabled={!filteredRestrictions.length}
          sx={{
            width: 26,
            height: 26,
            bgcolor: avatarBgColor(),
            ...(filteredRestrictions.length && {
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
              transition: 'transform 0.15s ease-in-out',
            }),
          }}
        >
          <Typography variant="subtitle2">
            {filteredRestrictions.length}
          </Typography>
        </Avatar>
      </Tooltip>
      {openDialog && (
        <FingoDialog
          title="Documentos Pendientes"
          subtitle={dialogSubtitle[type]}
          open={openDialog}
          onClose={setOpenDialog}
          handleClose={setOpenDialog}
          maxWidth="sm"
          fullWidth
        >
          <Stack spacing={1.5}>
            <Stack spacing={1}>
              {filteredRestrictions.map((detail) => (
                <Box
                  backgroundColor="gray.A200"
                  p={1}
                  sx={{ borderRadius: '15px', minHeight: '50px' }}
                  alignContent="center"
                >
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{ width: '5%' }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircleIcon
                        sx={{
                          height: '15px',
                          width: '15px',
                          color: 'primary.main',
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: detail.status !== 'CREATED' ? '65%' : '95%',
                        paddingLeft: '3px',
                      }}
                      display="flex"
                      alignItems="flex-start"
                    >
                      <Typography>{detail.restrictionLabel}</Typography>
                    </Box>
                    {detail.status !== 'CREATED' && (
                      <Box
                        sx={{ width: '30%' }}
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      >
                        <Chip
                          size="small"
                          color={RestrictionsColorMapper(detail.status)}
                          label={detail.statusLabel}
                        />
                      </Box>
                    )}
                  </Stack>
                </Box>
              ))}
            </Stack>
            <PendingDocumentsManagerFooterCell
              type={type}
              executiveAssigned={invoice.company.executiveAssigned}
            />
          </Stack>
        </FingoDialog>
      )}
    </>
  );
};

const detail = PropTypes.shape({
  id: PropTypes.string.isRequired,
  restrictionLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

PendingDocumentsManagerFooterCell.propTypes = {
  type: PropTypes.oneOf(['Risk', 'Operations']).isRequired,
  executiveAssigned: PropTypes.shape({
    id: PropTypes.string.isRequired,
    completeName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
};

PendingDocumentsManagerCell.propTypes = {
  type: PropTypes.oneOf(['Risk', 'Operations']).isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      executiveAssigned: PropTypes.shape({
        id: PropTypes.string.isRequired,
        completeName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
      }),
    }).isRequired,
    preoffer: PropTypes.shape({
      id: PropTypes.string,
      preofferevaluationrequest: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
    restrictedBy: PropTypes.arrayOf(detail),
  }).isRequired,
};

export default PendingDocumentsManagerCell;
