import React from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton } from '@mui/material';
import { Chat as ChatIcon } from '@mui/icons-material';
import { useBooleanState } from '../../hooks';
import ChatDialog from './ChatDialog';

const ChatButton = ({ invoice }) => {
  const [open, toggleOpen] = useBooleanState(false);
  return (
    <>
      <IconButton onClick={toggleOpen} size="large" id="chat-button">
        <Badge>
          <ChatIcon />
        </Badge>
      </IconButton>
      <ChatDialog
        open={open}
        onClose={toggleOpen}
        invoice={invoice}
      />
    </>
  );
};

ChatButton.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string,
    unreadMessages: PropTypes.number,
    binnacleSet: PropTypes.arrayOf(PropTypes.shape()),
    folio: PropTypes.string,
    mailInvoices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      createdAt: PropTypes.instanceOf(Date),
      status: PropTypes.shape({ status: PropTypes.string }),
      mailParameter: PropTypes.shape({
        mail: PropTypes.shape({
          emailName: PropTypes.string,
        }),
      }),
    })),
  }),
};

ChatButton.defaultProps = {
  invoice: {
    mailInvoices: [],
  },
};

export default ChatButton;
