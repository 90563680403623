import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { getTimeDiffWithHoursMinutes } from '../../helpers';
import dayjs from '../../config/dayjs';

const TimeInStageCell = ({ time }) => (
  <Typography variant="body2">
    {getTimeDiffWithHoursMinutes(dayjs(time))}
  </Typography>
);

TimeInStageCell.propTypes = {
  time: PropTypes.string.isRequired,
};

export default TimeInStageCell;
