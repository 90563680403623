import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../hooks';
import { getTimeDiffText } from '../../helpers';
import dayjs from '../../config/dayjs';

const DateCell = ({ date }) => {
  const isMobile = useIsMobile();
  const dateMoment = dayjs(date);
  return (
    <Stack justifyContent="center" alignItems="flex-start" direction="column">
      <Typography variant="body2">
        {dateMoment.format(isMobile ? 'DD MMM' : 'DD-MM-YYYY')}
      </Typography>
      {!isMobile && (
        <Typography variant="caption">{getTimeDiffText(dateMoment)}</Typography>
      )}
    </Stack>
  );
};

DateCell.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(dayjs)])
    .isRequired,
};

export default DateCell;
