import React, { useState } from 'react';
import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { Mention } from 'react-mentions';
import { styled, useTheme } from '@mui/material/styles';
import CustomMentionsInput from '../inputs/CustomMentionsInput';

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: '#f9f9f9',
  marginTop: 15,
  width: '-webkit-fill-available',
  padding: '10px 15px',
}));

const TextInput = ({ onSend, userMentions }) => {
  const theme = useTheme();
  const [message, setMessage] = useState('');
  const [mentions, setMentions] = useState([]);
  const sendMessage = () => {
    onSend(message.trim(), mentions.map((value) => value.id));
    setMessage('');
  };
  return (
    <StyledPaper>
      <CustomMentionsInput
        id="standard-text"
        placeholder="Escribe tu mensaje aquí"
        autoFocus
        value={message}
        onChange={(_, newValue, __, newMentions) => {
          setMessage(newValue);
          setMentions(newMentions);
        }}
        allowSuggestionsAboveCursor
        onKeyDown={(event) => {
          if (message.trim() && event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
          } else if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        customStyleMap={{
          'simple-mention': {
            color: theme.palette.primary.main,
          },
        }}
      >
        <Mention
          trigger="@"
          data={userMentions}
          displayTransform={(_, display) => `${display}`}
          appendSpaceOnAdd
          className="simple-mention"
        />
      </CustomMentionsInput>
    </StyledPaper>
  );
};

TextInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  userMentions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    display: PropTypes.string,
  })),
};

TextInput.defaultProps = {
  userMentions: [],
};

export default TextInput;
