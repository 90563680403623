import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Stack, Typography } from '@mui/material';
import reactStringReplace from 'react-string-replace';
import dayjs from '../../config/dayjs';

import { formatTime, formatDayWords } from '../../helpers';
import useGetUser from '../../hooks/useGetUser';

const useStyles = makeStyles((theme) => createStyles({
  messageRow: {
    display: 'flex',
  },
  messageRowRight: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  message: {
    position: 'relative',
    marginBottom: '10px',
    padding: '18px',
    minWidth: '30%',
    maxWidth: '60%',
    textAlign: 'left',
    border: 'none',
    borderRadius: '10px',
  },
  messageOther: {
    backgroundColor: theme.palette.primary.light,
    borderTopLeftRadius: 0,
  },
  messageOwn: {
    backgroundColor: 'transparent',
    borderTopRightRadius: 0,
    border: '1px solid #323232',
  },
  messageInfo: {
    backgroundColor: '#efefef',
  },
  messageTimeStampRight: {
    position: 'absolute',
    fontSize: '.85em',
    fontWeight: '300',
    marginTop: '10px',
    bottom: '-3px',
    right: '5px',
  },
}));

const markupMessage = (message, isOwnMessage, isInfoMessage) => {
  const regex = /@\[([a-zA-Z0-9.@_-]*)\]\([0-9]+\)/g;
  const replacer = (match) => (
    <Typography
      variant="subtitle1"
      component="span"
      color="primary"
      style={{ display: 'inline', fontWeight: 'bold' }}
    >
      {match}
    </Typography>
  );
  const colorChoicer = () => {
    if (isInfoMessage) {
      return {
        color: 'auto',
        textAlign: 'center',
      };
    }
    if (isOwnMessage) {
      return {
        color: 'auto',
        textAlign: 'auto',
      };
    }
    return {
      color: 'auto',
      textAlign: 'auto',
    };
  };
  return (
    <Typography variant="subtitle1" style={colorChoicer()}>
      {reactStringReplace(message, regex, replacer)}
    </Typography>
  );
};

const Message = ({ invoiceMessage, previousMessage }) => {
  const currentUser = useGetUser();
  const ownId = currentUser?.id;
  const classes = useStyles();
  const { user, message, createdAt } = invoiceMessage;
  const { user: previousUser, createdAt: previousCreatedAt } = previousMessage || {};
  const isOwnMessage = user && user.id === ownId;
  const isInfoMessage = !user;
  const firstUserMessage = !previousMessage || !previousUser || (
    user && previousUser.id !== user.id);
  const aligner = useMemo(() => {
    if (isInfoMessage) return 'center';
    if (isOwnMessage) return 'flex-end';
    return 'flex-start';
  }, [isOwnMessage, isInfoMessage]);
  const classNameChoicer = useMemo(() => {
    if (isInfoMessage) return classes.messageInfo;
    if (isOwnMessage) return classes.messageOwn;
    return classes.messageOther;
  }, [isInfoMessage, isOwnMessage, classes]);
  const showDate = useMemo(() => {
    if (!previousMessage) return true;
    const previousDate = dayjs(previousCreatedAt);
    const nowDate = dayjs(createdAt);
    return previousDate.year() !== nowDate.year()
      || previousDate.month() !== nowDate.month()
      || previousDate.date() !== nowDate.date();
  }, [createdAt, previousCreatedAt]);
  return (
    <Stack alignItems={aligner}>
      {showDate && (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ py: 2.5 }}>
          {formatDayWords(dayjs(invoiceMessage.createdAt))}
        </Stack>
      )}
      {user && (
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: firstUserMessage ? 'flex' : 'none' }}
          alignItems="center"
          justifyContent={aligner}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="subtitle1">
            {formatTime(dayjs(invoiceMessage.createdAt))}
          </Typography>
        </Stack>
      )}
      {!user && (
        <Stack
          direction="row"
          sx={{ display: firstUserMessage ? 'flex' : 'none' }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle1">
            {formatTime(dayjs(invoiceMessage.createdAt))}
          </Typography>
        </Stack>
      )}
      <div className={`${classes.message} ${classNameChoicer}`}>
        {markupMessage(message, isOwnMessage, isInfoMessage)}
      </div>
    </Stack>
  );
};

Message.propTypes = {
  invoiceMessage: PropTypes.shape({
    message: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  previousMessage: PropTypes.shape({
    message: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

Message.defaultProps = {
  previousMessage: null,
};

export default Message;
