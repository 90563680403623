import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import dayjs from '../../config/dayjs';

const LastActionDate = ({ actions }) => {
  const lastAction = actions.length > 0
    // eslint-disable-next-line max-len
    ? actions.reduce((prevAct, currAct) => (prevAct.createdAt > currAct.createdAt ? prevAct : currAct))
    : null;
  return (
    <Stack alignItems="center">
      <Typography variant="body1" fontSize={9} noWrap>
        {lastAction ? dayjs(lastAction.createdAt).format('DD-MM-YYYY') : ''}
      </Typography>
      <Typography variant="body1" fontSize={9} noWrap>
        {lastAction ? dayjs(lastAction.createdAt).format('HH:mm[hrs]') : ''}
      </Typography>
    </Stack>
  );
};

LastActionDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array.isRequired,
};

export default LastActionDate;
