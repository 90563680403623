import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Grid, Typography, LinearProgress, Tabs, Tab, Tooltip, Box, Button } from '@mui/material';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import { AccountCircle, Warning } from '@mui/icons-material';
import {
  FILE_URL,
  INVOICE_MESSAGES_QUERY,
  CREATE_INVOICE_MESSAGE,
  NEW_INVOICE_MESSAGE,
  InvoiceMessageFields,
  USER_MENTIONS_QUERY,
  READ_ALL_MESSAGES,
} from '../../graphql';
import { PencilIcon } from '../../assets/icons/tyc';
import { getTimeDiffText, openUrlAndDownload } from '../../helpers';
import TextInput from './TextInput';
import Message from './Message';
import { Table } from '../tables';
import { useGetUser } from '../../hooks';

const useStyles = makeStyles(() => createStyles({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 10,
  },
  messagesBody: {
    width: '-webkit-fill-available',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    scrollbarWidth: 'none',
    padding: '10px 15px',
    '&::-webkit-scrollbar': {
      display: 'none',
      width: 0,
    },
    backgroundColor: '#f9f9f9',
  },
  dialogContainer: {
    width: '100vw',
    height: '90%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f4f4f4',
  },
  pencilIcon: {
    height: '1.3em',
  },
}));

const defaultHeaders = [
  { id: 'folio', label: 'Folio', type: 'text', isSearchable: true, mobile: true, disableOrderBy: true },
  { id: 'companyName', label: 'Emisor', type: 'text', isSearchable: true, mobile: true, disableOrderBy: true },
  { id: 'receiverName', label: 'Deudor', type: 'text', isSearchable: true, mobile: true, disableOrderBy: true },
  { id: 'dateIssued', label: 'Fecha de emisión', type: 'date', isSearchable: true, disableOrderBy: true },
  { id: 'dateExpiration', label: 'Fecha de vencimiento', type: 'date', isSearchable: true, disableOrderBy: true },
];

const Chat = ({ open, invoice, dataToRows, headers }) => {
  const classes = useStyles();
  const user = useGetUser();
  const [messageType, setMessageType] = useState('internal');
  const [readAllMessages] = useMutation(READ_ALL_MESSAGES);
  const { data, loading, subscribeToMore: messageSubscriptions, fetchMore } = useQuery(
    INVOICE_MESSAGES_QUERY,
    {
      variables: {
        invoiceId: invoice?.id,
        first: 15,
        messageType,
      },
      skip: !invoice?.id,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );
  const { data: userMentions } = useQuery(
    USER_MENTIONS_QUERY,
    {
      variables: {
        invoiceId: invoice?.id,
        messageType,
      },
      skip: !invoice?.id,
    },
  );
  const [_createMessage] = useMutation(CREATE_INVOICE_MESSAGE, {
    awaitRefetchQueries: true,
    refetchQueries: [INVOICE_MESSAGES_QUERY],
    onError: () => { },
  });
  const createMessage = async (message, userIdMentions) => {
    await _createMessage({
      variables: {
        invoiceId: invoice.id,
        message,
        messageType: messageType.toUpperCase(),
        userIdMentions,
      },
      update: (cache, { data: { createInvoiceMessage } }) => {
        cache.modify({
          fields: {
            invoiceMessages(messages) {
              const newTodoRef = cache.writeFragment({
                data: createInvoiceMessage.createdMessage,
                fragment: InvoiceMessageFields,
              });
              return {
                ...messages,
                edges: [{ cursor: '', node: newTodoRef }, ...messages.edges],
              };
            },
          },
        });
      },
      optimisticResponse: {
        createInvoiceMessage: {
          __typename: 'CreateInvoiceMessage',
          createdMessage: {
            __typename: 'InvoiceMessageType',
            id: 'some-temp-id',
            user: {
              __typename: 'UserType',
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
            },
            message,
            messageType,
            invoice: {
              __typename: 'InvoiceType',
              id: invoice.id,
            },
          },
        },
      },
    });
  };
  const [getInvoicePdf] = useMutation(FILE_URL, {
    onCompleted: (_data) => {
      if (_data.invoicePdfUrl?.url) {
        openUrlAndDownload(_data.invoicePdfUrl.url, 'pdf');
      }
    },
  });
  const defaultDataToRows = useCallback((_data) => _data.map((receivedInvoice) => {
    const {
      id,
      folio,
      dateIssued,
      dateExpiration,
      loanedStatus,
      loanedTo,
      company: {
        masterEntity: {
          name: companyName,
        },
      },
      receiver: {
        name: receiverName,
      },
    } = receivedInvoice;
    return ({
      id,
      nonSelectable: true,
      folio: {
        value: folio,
        component: (
          <Tooltip title="Ver PDF">
            <Box>
              <Button onClick={() => getInvoicePdf({ variables: { invoiceId: id } })} color="primary">
                <Grid container alignItems="center" justifyContent="center">
                  <Typography
                    align="center"
                    variant="subtitle1"
                  >
                    {folio}
                  </Typography>
                </Grid>
              </Button>
            </Box>
          </Tooltip>
        ),
        size: 'small',
      },
      dateIssued: {
        value: dateIssued,
        component: (
          <Grid container justifyContent="center" alignItems="flex-start" direction="column">
            <Typography variant="subtitle1">{dateIssued.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateIssued)}</Typography>
          </Grid>
        ),
        size: 'small',
      },
      dateExpiration: {
        value: dateExpiration,
        component: (
          <Grid container justifyContent="center" alignItems="flex-start" direction="column">
            <Typography variant="subtitle1">{dateExpiration.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateExpiration)}</Typography>
          </Grid>
        ),
        size: 'small',
      },
      companyName: {
        value: companyName,
        component: (
          <Grid container alignItems="flex-start" justifyContent="center" direction="column">
            <Typography variant="subtitle1">
              {companyName}
            </Typography>
            {loanedStatus === 'LOANED' && (
              <Grid container item alignItems="center" justifyContent="flex-start">
                <Warning color="warning" />
                <Typography variant="caption">Cedida {loanedTo && `a ${loanedTo}`}</Typography>
              </Grid>
            )}
          </Grid>
        ),
        size: 'small',
      },
      receiverName: {
        value: receiverName,
        component: (
          <Grid container alignItems="flex-start" justifyContent="center" direction="column">
            <Typography variant="subtitle1">
              {receiverName}
            </Typography>
            {loanedStatus === 'LOANED' && (
              <Grid container item alignItems="center" justifyContent="flex-start">
                <Warning color="warning" />
                <Typography variant="caption">Cedida {loanedTo && `a ${loanedTo}`}</Typography>
              </Grid>
            )}
          </Grid>
        ),
        size: 'small',
      },
    });
  }), [getInvoicePdf]);
  const dataToRowsFunction = dataToRows || defaultDataToRows;
  useEffect(() => {
    if (invoice?.id) {
      const unsubscribe = messageSubscriptions({
        document: NEW_INVOICE_MESSAGE,
        variables: {
          invoiceId: invoice?.id,
        },
        updateQuery: (prev, { subscriptionData: { data: subscribeData } }) => {
          if (!subscribeData) {
            return prev;
          }
          return {
            ...prev,
            invoiceMessages: {
              ...prev.invoiceMessages,
              edges: [
                {
                  cursor: (prev.invoiceMessages.edges?.length
                    && prev.invoiceMessages.edges[0].cursor),
                  node: { ...subscribeData.newInvoiceMessage.invoiceMessage },
                },
                ...prev.invoiceMessages.edges,
              ],
            },
          };
        },
      });
      return unsubscribe;
    }
    return () => { };
  }, [invoice, messageSubscriptions]);
  useEffect(() => {
    if (open && invoice && invoice.unreadMessages > 0) {
      readAllMessages({ variables: { invoiceId: invoice.id } });
    }
  }, [open, invoice, readAllMessages]);
  const scroller = (event) => {
    event.preventDefault();
    if (data?.invoiceMessages?.pageInfo?.hasNextPage && !loading) {
      const { currentTarget: { scrollTop, clientHeight, scrollHeight } } = event;
      const bottom = scrollHeight + scrollTop - clientHeight <= 1;
      if (bottom) {
        fetchMore({
          variables: {
            after: data.invoiceMessages.pageInfo.endCursor,
          },
        });
      }
    }
  };
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="h5" color="primary">Mensajes:</Typography>
        </Grid>
        <Grid item xs={9}>
          {invoice && (
            <Table
              headers={defaultHeaders.filter((header) => {
                const headerIds = headers.map((_header) => _header.id);
                return headerIds.includes(header.id);
              })}
              data={[invoice]}
              dataToRows={dataToRowsFunction}
              setNewDataOrder={() => { }}
              pagination={false}
              initialOrderId=""
            />
          )}
        </Grid>
      </Grid>
      <Paper className={classes.messagesBody} onScroll={scroller} style={{ height: '100%' }}>
        <Grid container alignItems="center" style={{ height: 60 }} spacing={2}>
          <Grid container item xs={6} spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid item xs={2}>
              <AccountCircle fontSize="large" />
            </Grid>
            <Grid item xs={10} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <Typography variant="subtitle1">
                {user.firstName}{' '}{user.lastName} {user.selectedCompany && `de ${user.selectedCompany.name}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Tabs
              onChange={(_, value) => setMessageType(value)}
              centered
              value={messageType}
              indicatorColor="primary"
              variant="fullWidth"
            >
              <Tab
                value="internal"
                label={(
                  <Grid container alignItems="center" spacing={2}>
                    <Grid container item xs={3} justifyContent="flex-end">
                      <PencilIcon classes={{ root: classes.pencilIcon }} />
                    </Grid>
                    <Grid container item xs={9}>
                      <Typography variant="subtitle1">Mensaje interno</Typography>
                    </Grid>
                  </Grid>
                )}
                wrapped
              />
              <Tab
                value="suppliers"
                label={(
                  <Grid container alignItems="center" spacing={2}>
                    <Grid container item xs={3} justifyContent="flex-end">
                      <MessageRoundedIcon />
                    </Grid>
                    <Grid container item xs={9}>
                      <Typography variant="subtitle1">Mensaje a proveedor</Typography>
                    </Grid>
                  </Grid>
                )}
                wrapped
              />
            </Tabs>
          </Grid>
        </Grid>
        <Grid className={classes.messagesBody} onScroll={scroller} style={{ padding: 0 }}>
          {data?.invoiceMessages.edges.map((invoiceMessage, index) => (
            <Message
              key={invoiceMessage.node.id}
              invoiceMessage={invoiceMessage.node}
              previousMessage={data.invoiceMessages.edges[index + 1]
                && data.invoiceMessages.edges[index + 1].node}
            />
          ))}
          {loading && (
            <Grid>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
      </Paper>
      {invoice?.binnacleSet?.length
        ? (
          <Typography
            marginTop={2}
            width="100%"
            variant="caption"
            color="gray"
            inline
          >Rechazada por {invoice.binnacleSet[0].username} (<b>{invoice.binnacleSet[0].mail}</b>),
            contáctalo con un &quot;@&quot; en el chat
          </Typography>
        ) : (<></>)}
      <TextInput
        onSend={createMessage}
        userMentions={userMentions?.userDisplayForMentionInvoice}
      />
    </>
  );
};

Chat.propTypes = {
  open: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    unreadMessages: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    binnacleSet: PropTypes.array,
  }),
  dataToRows: PropTypes.func,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      extraDataKey: PropTypes.string,
      extraDataKey2: PropTypes.string,
      isSearchable: PropTypes.bool,
      align: PropTypes.string,
      size: PropTypes.number,
      disableOrderBy: PropTypes.bool,
      disablePadding: PropTypes.bool,
    }),
  ),
};

Chat.defaultProps = {
  invoice: null,
  dataToRows: null,
  headers: defaultHeaders,
};

export default Chat;
