import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { preofferCacheStatusToText } from '../../helpers/preoffer-cache-status-to-text';

const FactoringStatusCell = ({ invoice }) => (
  <Typography variant="body2">
    {preofferCacheStatusToText(invoice)}
  </Typography>
);

FactoringStatusCell.propTypes = {
  invoice: PropTypes.shape({
    preofferSelectable: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default FactoringStatusCell;
