import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DicomComponent from '../dicom/DicomComponent';
import DateTimeCell from './DateTimeCell';
import { formatMoney } from '../../helpers/money';
import dayjs from '../../config/dayjs';

const DicomDetailsCell = ({ masterEntity }) => {
  const {
    currentDicom: dicom = null,
    currentDicomPlatinum: dicomPlatinum = null,
    isNaturalPersonRut,
  } = masterEntity ?? {
    currentDicom: null,
    currentDicomPlatinum: null,
    isNaturalPersonRut: false,
  };
  const createdAt = useMemo(
    () => dicom?.createdAt ?? dicomPlatinum?.createdAt,
    [dicom, dicomPlatinum],
  );
  const amount = useMemo(
    () => dicom?.dicomAmount ?? dicomPlatinum?.dicomAmount,
    [dicom, dicomPlatinum],
  );

  return (
    <Stack direction="row" spacing={2}>
      {masterEntity && (
        <DicomComponent
          isNaturalPersonRut={isNaturalPersonRut}
          masterEntityId={masterEntity.id}
          disabled={createdAt === undefined}
        />
      )}
      {createdAt && (
        <Stack direction="column" alignSelf="center">
          <DateTimeCell fullDate={dayjs(createdAt)} />
          <Typography>${formatMoney(amount)}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

DicomDetailsCell.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string,
    currentDicom: PropTypes.shape({
      dicomAmount: PropTypes.number,
      createdAt: PropTypes.string,
    }),
    currentDicomPlatinum: PropTypes.shape({
      dicomAmount: PropTypes.number,
      createdAt: PropTypes.string,
    }),
    isNaturalPersonRut: PropTypes.bool,
  }).isRequired,
};

export default DicomDetailsCell;
